import React, { useEffect, useState } from 'react'
import { Box, Flex, Spinner, Image, Link } from '@chakra-ui/react'
import { loadForm } from '../db'
import { Helmet } from 'react-helmet'
import { isBrowser } from '../utils/isBrowser'
import { navigate } from 'gatsby'
import poweredByFacefile from '../images/powered_by_facefile.svg'

const re = {
  preview: /preview/,
  url: /(http(s?)):\/\//i
}

const Preview = () => {
  const [url, setUrl] = useState(null)
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState()
  const [hideFacefileLogo, setHideFacefileLogo] = useState(true)

  const formId =
    isBrowser &&
    window.location.pathname
      .split(/\/s\//)
      .filter(Boolean)
      .join('')

  const containsPreview = isBrowser && re.preview.test(window.location.search)

  const redirectToNotFound = () => navigate('/404', { replace: true })

  useEffect(() => {
    const getForm = async () => {
      try {
        setLoading(true)
        const formData = await loadForm(formId, containsPreview)
        console.log('load form', formData)
        if (formData) {
          setUrl(formData.url)
          setProfile(formData.accountProfile)
          setHideFacefileLogo(Boolean(formData.hideFacefileLogo))
          setLoading(false)
        } else {
          redirectToNotFound()
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
        redirectToNotFound()
      }
    }
    getForm()
  }, [containsPreview, formId])

  const renderFavicon = () => {
    // console.log('faviconUrl', faviconUrl)
    if (
      profile &&
      profile.favicons &&
      Object.keys(profile.favicons).length > 0
    ) {
      return (
        <Helmet>
          <link
            id='favicon'
            rel='icon'
            href={profile.favicons['ico']}
            type='image/x-icon'
          />
          {[32, 128, 180, 192].map(size => {
            return (
              <link
                key={size}
                id={`favicon${size}`}
                rel='icon'
                href={profile.favicons[`icon${size}`]}
                sizes={`${size}x${size}`}
                type='image/png'
              />
            )
          })}
          <link
            id='favicon180'
            rel='apple-touch-icon'
            href={profile.favicons['icon180']}
            type='image/png'
          />
        </Helmet>
      )
    } else {
      return (
        <Helmet>
          <link
            rel='icon'
            href='/favicon-32x32.png?v=30e11cdb8e2cb23bd653b440a058718b'
            type='image/png'
            id='favicon'
          />
        </Helmet>
      )
    }
  }

  const renderLogo = () => {
    if (profile && profile.logoUrl && hideFacefileLogo) {
      const logo = (
        <Box
          position='absolute'
          left={{ base: 4, lg: 8 }}
          bottom={{ base: 'auto', lg: 8 }}
          top={{ base: 6, lg: 'auto' }}
        >
          <Image alt='logo' h={{ base: 6, lg: 12 }} src={profile.logoUrl} />
        </Box>
      )
      if (profile.logoRedirectUrl) {
        return (
          <Link isExternal href={profile.logoRedirectUrl}>
            {logo}
          </Link>
        )
      } else {
        return logo
      }
    } else if (!hideFacefileLogo) {
      return (
        <Flex
          position='absolute'
          left={{ base: 0, lg: 0 }}
          bottom={{ base: 'auto', lg: 0 }}
          top={{ base: 2, lg: 'auto' }}
          w={{ base: undefined, lg: '50%' }}
          justify={'center'}
          align='center'
          bg='blackAlpha.100'
          py={{ base: 1, lg: 2 }}
        >
          <Link href={'/'}>
            <Image alt='logo' h={{ base: 3, lg: 6 }} src={poweredByFacefile} />
          </Link>
        </Flex>
      )
    }
  }

  return (
    <Box w='full' h='full' position={'relative'}>
      {loading ? (
        <Flex
          w='full'
          h='full'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <Spinner size='xl' />
        </Flex>
      ) : (
        <>
          <iframe
            src={url}
            allow='camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;'
            title={formId}
            width='100%'
            height='100%'
          />
          {renderFavicon()}
          {renderLogo()}
        </>
      )}
    </Box>
  )
}

export default Preview
