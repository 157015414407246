export const loadForm = async (formId, isPreview) => {
  try {
    console.log('loadForm', formId, 'isPreview', isPreview)
    let url = `https://us-central1-tenantflow-ace23.cloudfunctions.net/p/videoForm/${formId}`
    if (isPreview) {
      url = url + '?preview=1'
    }
    console.log('fetch url', url)
    const res = await fetch(url)
    if (res.status === 200) {
      const data = await res.json()
      console.log('data', data)
      return data
    } else {
      const t = await res.text()
      console.log('res.status is not 200:', t)
      return null
    }
  } catch (e) {
    console.warn('loadForm error:', e)
  }
}
