import React, { useEffect } from 'react'
import _ from 'lodash'

import Preview from '../../components/Preview'

const PreviewPage = () => {
  useEffect(() => {
    const run = async () => {
      while (!_.get(window, 'MissiveChat')) {
        await new Promise(resolve => setTimeout(resolve, 100))
      }
      hideWidget()
    }
    run()
  }, [])

  const hideWidget = () => {
    const mch = window.MissiveChat
    if (mch && mch.hideButton) {
      mch.hideButton()
    }
  }
  return <Preview />
}

export default PreviewPage
